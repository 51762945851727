<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">
        <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'px-5 pt-8' : 'px-5'">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="#d31145"
            :class="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'hidden-arrow' : ''"
            slider-size="4">
            <v-tabs-slider color="#d31145"></v-tabs-slider>
            <v-tab
              v-for="(item, index) in menu"
              :key="index"
              class="text-capitalize">
              <span class="body-2" :class="tab === index ? 'font-weight-bold color-default' : 'text-third'">
                {{ item.label }}
              </span>
            </v-tab>
          </v-tabs>
        </div>
        <v-sheet 
          class="overflow-y-auto"
          :max-height="$vuetify.breakpoint.name === 'xs' ? 'calc(100vh - 100px)' : 'calc(100vh - 125px)'">

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="(item, index) in menu"
              :key="index">
              <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-8 mt-2' : 'px-4 mt-2'">
                <strong>{{ recap_detail.name }}</strong>
                <br>
                Daftar Outlet : 
                <v-chip v-for="(item, key) in data_store" :key="key" small class="mr-2 white--text text-center" color="#d31145">
                  {{ item.store_name }}
                </v-chip>
              </div>
              <router-view :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'px-5 pt-4' : 'px-5'" :model="tab"/>
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { TokenService } from "@/service/Storage.Service";
  import { get } from "@/service/Axios"
  export default {
    data () {
      return {
        tab: 1,
        // recap_detail: {},
        // data_store: [],
        menu: [
          {
            label: 'Stok',
            value: `/report-recap-stock/monitoring/${this.$route.params.id}`
          },
          {
            label: 'Kartu Stok',
            value: `/report-recap-stock/card/${this.$route.params.id}`
          },
        ]
      }
    },
    components: {
      
    },
    computed: {
      user () {
        return JSON.parse(TokenService.getUser())
      },
      recap_detail() {
        return JSON.parse(localStorage.getItem("setStoreRecapDetail"));
      },
      data_store() {
        return JSON.parse(localStorage.getItem("setStoreRecap"));
      }
    },
    watch: {
      'tab': function(val) {
        // if (val >= 0) {
          if(this.$route.path != this.menu[val].value){
            this.$router.push({ path: `${this.menu[val].value}` })
          // }
        }
      },
      '$route' (to, from) { 
        // nothing
      } 
    },
    mounted () {
      let index = this.menu.findIndex((item) => item.value === this.$route.path);
      this.tab = index;
    },
    methods: {
      async getDetailRecapStock() {
        // console.log('iki loh cuk');
        await get(`franchise/report/recap_job/stock/detail?id=${this.$route.params.id}`).then(async (response) => {
          let res = response.data;
          if (res.status == 200) {
            this.recap_detail = res.results.data
          }
        })
      },
      async getStore() {
        await get(`franchise/report/recap_job/stock/store`, {
          params: {
            id: this.$route.params.id,
            pagination_bool: false
          }
        }).then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.data_store = res.results.data
          }
        })
      },
    },
  }
</script>